export function stableSort(array: Array<any>, comparator: ( a: any, b: any ) => number) {
	const stabilizedThis = array.map((el: any, index: number) => ({el, index}));
	stabilizedThis.sort((a, b) => {
		const order = comparator(a.el, b.el);
		if (order !== 0) return order;
		return a.index - b.index;
	});
	return stabilizedThis.map((element) => element.el);
}

export function descendingComparator(a: any, b: any, orderBy: string) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

export function getComparator(order: 'desc' | 'asc', orderBy: string) {
	return order === 'desc'
		? (a: any, b: any) => descendingComparator(a, b, orderBy)
		: (a: any, b: any) => -descendingComparator(a, b, orderBy);
}


// This is for Risk Stratification sort
export function descendingComparator2(a: any, b: any, orderBy: string) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	if (typeof b[orderBy] === 'object' && b[orderBy] !== null){
		if (b[orderBy].hasOwnProperty("color")){
			if (b[orderBy]["color"]===a[orderBy]["color"]){
				return descendingComparator2(a[orderBy], b[orderBy], "value");
			}
			else {
				if (a[orderBy]["color"]==="error"){
					return -1
				}
				if (b[orderBy]["color"]==="error"){
					return 1
				}
				if (a[orderBy]["color"]==="success"){
					return 1
				}
				if (b[orderBy]["color"]==="success"){
					return -1
				}

			}
		}
	}
	return 0;
}

export function getComparator2(order: 'desc' | 'asc', orderBy: string) {
	return order === 'desc'
		? (a: any, b: any) => descendingComparator2(a, b, orderBy)
		: (a: any, b: any) => -descendingComparator2(a, b, orderBy);
}