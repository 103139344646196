export enum StaffReportActions {
  SetComparingDataSet = "SET_COMPARING_DATA_SET",
  SetInterval = "SET_INTERVAL",
  SetStartDate = "SET_START_DATE",
}

export interface IStaffReportActions {
  type: StaffReportActions;
  payload: any;
}

export function setComparingDataSet(value: any): any {
  return {
    type: StaffReportActions.SetComparingDataSet,
    payload: value,
  };
}

export function setInterval(value: any): any {
  return {
    type: StaffReportActions.SetInterval,
    payload: value,
  };
}

export function setStartDate(value: any): any {
  return {
    type: StaffReportActions.SetStartDate,
    payload: value,
  };
}
