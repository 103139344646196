import moment from 'moment';
import { IStaffReportActions, StaffReportActions } from './actions';

export type StaffReportStateType = {
	interval: string,
	comparingDataSet: any,
	startDate: Date,
}

const TODAY = new Date();
const WEEK_AGO = moment(TODAY).subtract(6, 'days').toDate();

let initialState: StaffReportStateType = {
	interval: 'weekly',
	comparingDataSet: [],
	startDate: WEEK_AGO,
}

export function staffReportReducer(state = initialState, actions: IStaffReportActions) {
	switch (actions.type) {
		case StaffReportActions.SetComparingDataSet:
			return {
				...state,
				comparingDataSet: actions.payload
			}

		case StaffReportActions.SetInterval:
			return {
				...state,
				interval: actions.payload
			}

		case StaffReportActions.SetStartDate:
			return {
				...state,
				startDate: actions.payload
			}

		default:
			return state
	}
}