import { colors } from '@material-ui/core';

const palette = {
    background: {
        default: '#F4F6F8',
        paper: colors.common.white
    },
    primary: {
        light: '#fe514c',
        main: '#c40d23',
        dark: '#8b0000',
        contrastText: '#FFFFFF'
    },
    secondary: {
        light: '#7f4370',
        main: '#511845',
        dark: '#29001e',
        contrastText: '#ffffff'
    },
    error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
        contrastText: '#ffffff'
    },
    warning: {
        light: '#ffb74d',
        main: '#ff9800',
        dark: '#f57c00',
        contrastText: '#ffffff'
    },
    info: {
        light: '#64b5f6',
        main: '#2196f3',
        dark: '#1976d2',
        contrastText: '#ffffff'
    },
    success: {
        light: '#81c784',
        main: '#4caf50',
        dark: '#388e3c',
        contrastText: '#ffffff'
    },
    text: {
        primary: '#172b4d',
        secondary: '#6b778c'
    }
};

export default palette;