import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

export default function Terms() {
	const { t } = useTranslation();

	return (
		<Box sx={{
			height: '400px',
			overflow: 'scroll',
			overflowX: 'hidden',
		}}>
			<Typography component='h1' variant='h1' align='center' gutterBottom>
				{t('common.terms')}
			</Typography>
			<p>
				&emsp; 株式会社アドダイス（以下、「当社」といいます）は、当社が運営し提供するサービス「ResQ AI」（以下、当該サービスを利用する際にインストールが必要となるアプリケーションおよび健康データを収集するウェアラブルIoT端末と合わせ、「本サービス」といいます）について、以下のとおり利用規約（以下、「本規約」といいます）を定めます。<br/><br/>
				<br/>
				<b>第1条. (はじめに)</b><br/><br/>
				1. 本サービスを利用するには、本規約に定める手続きに従い、利用者となる必要があります。<br/><br/>
				2．利用者登録の希望者は、登録ページから必要事項を入力の上希望者自ら利用者登録を行うものとし利用者登録画面において「登録」ボタンを押した段階で当社との間で本規約に従った契約が成立し利用者登録が完了するものとし、代理による登録申請は認めないものとします。ただし、当社が別途に指定する方法を案内する場合はその方法に従い利用者申請することができます。<br/><br/>
				3．当社は、利用者登録希望者が以下の各号の何れかに該当する場合、当社の判断により当該登録を取消すことがあります。<br/><br/>
				&emsp; (1) 利用者登録の希望者が、過去に当社との間で成立した規約（本規約を含みますが、これに限りません）に違反したこと等により、利用者登録の抹消等の処分を受けていたことが判明した場合<br/><br/>
				&emsp; (2) 利用者登録の希望者の申請内容に虚偽の事項が含まれていることが判明した場合<br/><br/>
				&emsp; (3) 利用者登録の希望者が、過去に当社の提供する何らかのサービスに関して、正当な理由なく、料金等の支払債務の履行遅延、長期間に亘る本商品の受取り不能、返品・交換の拒絶その他の債務不履行があったことが判明した場合<br/><br/>
				&emsp; (4) 本条第1項に定める代理による登録申請であったことが判明した場合<br/><br/>
				&emsp; (5) 利用者登録の希望者の所在地が日本国外であった場合<br/><br/>
				&emsp; (6) その他本サービスの運営・管理上、不適当であると当社が合理的に判断した場合<br/><br/>
				4．本規約において利用者とは、本規約の内容に全て同意することを前提として、当社所定の手続に従い利用者登録を申請し、当社がこれを承認した者をいいます。利用者は本サービスを利用することができます。利用者は、年齢や利用環境等、以下各号に定める内容を正確に理解したうえで、当社の定める条件に応じて、当社の定める範囲内で、本サービスを利用するものとします。<br/><br/>
				&emsp; ①本サービスは、利用者の健康データを共有するもので、医師による診療行為又はこれに準ずる行為の提供を行うものではありません。本利用者は、本サービスが提供する情報が医療行為ではないことを十分に認識した上で自己の責任において本サービスを利用してください。<br/><br/>
				&emsp; ②利用者は、本サービスに基づく情報を根拠にした確定的判断を行うことなく、必ず自身の判断に基づき、必要に応じて適宜医療機関の受診等を行ってください。<br/><br/>
				&emsp; ③利用者は、本サービスに基づく情報は、限られた情報に基づくものであって、利用者の判断の参考とするためのものであることを十分に理解し、必要に応じて適宜医療機関の受診等を行ってください。本サービスで利用者が閲覧できる値が正常な値域となっていた時でも健康を保証するものではありませんのでこのような時でも医療機器での計測および医療機関での受診を優先して下さい。<br/><br/>
				&emsp; ④利用者は、健康の不安および病気などについての質問および相談は医療機関に対して行って下さい。本サービスを構成するResQ Bandの故障やResQ Liveアプリの使い方についてのみアドダイスにお問い合わせください。<br/><br/>
				5. 当社は、本サービス及び本サービスを利用して提供される一切のサービスについて、いつでも任意にその内容を変更し、中断しまたは終了することができるものとします。 <br/><br/>
				6. 本規約に同意しない利用者は、本サービスを利用することはできないものとし、直ちに本サービスのダウンロード、インストールまたは利用を中止するものとします。利用者が本サービスを既にインストールしている場合、そのアンインストールを行うものとします。 <br/><br/>
				7. 利用者は、本サービスの利用を、自身の所有するスマートフォン端末ないしは自身の所有するパーソナルコンピューター端末（以下、単に「端末」といいます）の操作によって行うものとします。<br/><br/>
				<b>第2条.（本利用契約の変更）</b><br/><br/>
				１　当社は、当社が必要と判断する場合、利用者の承諾を得て、本利用契約の内容を変更又は追加できるものとする。但し、次の各号の一に該当する場合、利用者の承諾があったものとみなすことができるものとする。<br/><br/>
				&emsp; (1) 当該変更又は追加が、利用者の一般の利益に適合するとき<br/><br/>
				&emsp; (2) 当該変更又は追加が、本利用契約を締結した目的に反せず、かつ、必要性、内容の相当性、その他の当該変更又は追加に係る事情に照らして合理的なものであるとき<br/><br/>
				２　当社は、前項の変更又は追加を行うときは、事前にその旨及び当該変更又は追加の内容並びにその効力発生時期をインターネットの利用その他の適切な方法により周知するものとする。<br/><br/>
				<b>第3条. (著作権等の権利の帰属)</b><br/><br/>
				1. 本サービスを構成する文章、画像、プログラムその他一切の情報について発生している著作権その他の知的財産権、肖像権及びパブリシティ権その他の人格権ならびに所有権その他の財産権は、利用者が自ら作成したものに関する権利を除き、当社または当該権利を有する第三者に帰属します。 <br/><br/>
				2. 当社は、利用者が本サービスにおいて投稿、アップロードまたは保存した全ての情報（文字情報、画像情報等を含みますがこれらに限られません。以下、「利用者コンテンツ」といいます）について、これらを保存・蓄積した上、本サービスの円滑な運営及び改善を目的として、無償、無期限かつ無条件で、あらゆる態様で利用でき、利用者はこれに同意するものとします。<br/><br/>
				<b>第4条. (利用者情報及び利用者コンテンツの取扱い)</b><br/><br/>
				1. 当社は、本サービスに関し、次に掲げる情報（以下、「利用者情報」といいます）を取得することがあります。 <br/><br/>
				&emsp; (1) 利用者個人に関する情報：利用者が本サービスに登録し、当社に送信したアカウント名（利用者の実名である場合を含みます）、電話番号、メールアドレス、生年月日及び性別等。 <br/><br/>
				&emsp; (2) 利用端末に関する情報：使用デバイス、OS、端末言語設定、アクセス国等。 <br/><br/>
				&emsp; (3) 通信環境に関する情報：通信キャリア、通信環境、アクセス地域等。 <br/><br/>
				&emsp; (4) 利用状況に関する情報：本サービスのバージョン、本サービスの利用履歴等。 <br/><br/>
				2. 当社は、利用者情報を保存・蓄積した場合、当社のプライバシーポリシーに基づき、善良な管理者の注意義務に従い取り扱うとともに、次の各号の目的で使用します。 <br/><br/>
				&emsp; (1) 本サービスの運営（当社から利用者に対して、あらゆる情報を提供することを含みます）。 <br/><br/>
				&emsp; (2) 当社が利用者にとって有益だと判断する当社のサービス（本サービスに限りません）または広告主企業や提携先企業の商品、サービス等に関する情報の提供。 <br/><br/>
				&emsp; (3) 利用者に対する、本サービスの運営に著しく影響を及ぼす事柄（本サービス内容の大幅な変更、一時停止を含みますがこれらに限られません）に関する連絡。 <br/><br/>
				&emsp; (4) 利用者に対する、利用者情報の取扱いに関する同意を求めるための連絡。 <br/><br/>
				&emsp; (5) 本サービスの利用状況等に係る統計情報の作成、本サービスまたは当社ウェブサイト等における公表及び第三者への販売その他の提供。 <br/><br/>
				&emsp; (6) 利用者の年齢、職業、性別、趣味・嗜好等に合わせた広告その他の情報の提供。 <br/><br/>
				&emsp; (7) 本サービスに関するマーケティング分析をはじめ、研究開発全般での利用 <br/><br/>
				3. 当社は、利用者情報について、次の各号の場合を除き、本人以外の第三者に開示しないものとします。 <br/><br/>
				&emsp; (1)本サービスの向上、関連事業開発、研究開発及び提携企業のマーケティング等の目的で利用者情報を集計及び分析等する場合 <br/><br/>
				&emsp; (2)前号の集計及び分析等により得られたものを、個人を識別又は特定できない態様にて、第三者に開示又は提供する場合 <br/><br/>
				&emsp; (3)個人情報の開示や利用について利用者の同意がある場合 <br/><br/>
				&emsp; (4)利用者が希望するサービスを提供するために、個人情報の開示や利用が必要と認められる場合 <br/><br/>
				&emsp; (5)利用者が希望するサービスを提供する目的で、当社との間で契約を締結した提携先企業等の第三者（以下、「パートナー」といいます）が個人情報を必要とする場合（なお、パートナーは、当社が提供した個人情報をサービス提供のために必要な範囲を超えて利用することはできません） <br/><br/>
				&emsp; (6)当社がサービスとして提供するアンケート等への回答を依頼された利用者が、回答情報として当社に提供した文字、数字、静止画、動画像その他の情報を第三者に提供する場合（この場合、当社の情報提供先は当該アンケートの発注元及びパートナーに限るものとし、利用者個人が特定できない形式での提供となります） <br/><br/>
				&emsp; (7)当社又はパートナーの広告、サービス等に関する情報を、利用者に送信する場合 <br/><br/>
				&emsp; (8)法令に基づく場合 <br/><br/>
				&emsp; (9)当社、利用者その他第三者の生命、身体もしくは財産、又は当社が提供する一切のサービスの保護のために必要がある場合 <br/><br/>
				&emsp; (10)公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合 <br/><br/>
				&emsp; (11)国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合、これらの者から正当に開示を求められた場合 <br/><br/>
				4. 当社は、利用者情報及び利用者コンテンツの保存義務を負わず、これらの完全性、可用性について保証しません。利用者は、利用者情報及び利用者コンテンツを保全したい場合、自らの責任によりバックアップをとるものとします。 <br/><br/>
				5.当社は、利用者が本サービスを退会し、または当社が本サービスの運営を終了したときは、当社の判断により、利用者情報及び利用者コンテンツを全て消去できるものとし、その返却等の要望には応じません。<br/><br/>
				<b>第5条. (料金及び費用)</b><br/><br/>
				1. 当社は、利用者に対して、利用者の選択によって追加または拡張できる機能の全部または一部を有料とし、その他有料のサービス（本サービス内で利用できるポイント、アイテム等の販売等を含みますが、これらに限定されません）を提供します。 <br/><br/>
				2. 当社は、本サービスにおいて有料または無料とされている機能について、利用者が申し込んだ際に提示された価格表に基づいて合意した料金で提供します。当社の判断により、その価格を変更する場合があります。利用者は、当該変更に承諾しない場合、本サービスの全部または一部を利用できなくなる場合があることについて、予め承諾するものとします。 <br/><br/>
				3. 本サービスの利用にあたって必要な端末の購入、導入または維持にかかる費用、パケット料、データ利用料などの通信料その他の一切の費用は、利用者の負担とします。<br/><br/>
				<b>第6条. (禁止事項)</b><br/><br/>
				利用者は、本サービスの利用にあたり、次の各号の行為またはそのおそれがある行為を行ってはならないものとします。 <br/><br/>
				&emsp; (1) 法令、公序良俗または本規約に違反する行為 <br/><br/>
				&emsp; (2) 当社または第三者の著作権その他の知的財産権、肖像権及びパブリシティ権その他の人格権ならびに所有権その他の財産権を侵害する行為 <br/><br/>
				&emsp; (3) 当社または第三者を不当に差別しまたは誹謗中傷する行為、当社または本サービスの名誉・信用を毀損する行為 <br/><br/>
				&emsp; (4) 第三者へのいやがらせ、わいせつ、悪口、嫌悪感を与える表現や内容を含む一切の利用者コンテンツを投稿し、書き込みし、またはアップロードする行為 <br/><br/>
				&emsp; (5) アダルトサイト、出会い系サイト等へのリンクを掲載する行為、異性との出会いを希望または誘導することを目的とする行為 <br/><br/>
				&emsp; (6) 氏名、名称、電話番号、メールアドレス、住所など特定の個人を特定できる情報、または企業、団体などの非公開情報、営業秘密、秘密情報を、本人または権利者の許可なく取得、開示、改ざんもしくは漏洩させる行為 <br/><br/>
				&emsp; (7) 第三者になりすまし、その他虚偽の情報を登録及び掲載する行為 <br/><br/>
				&emsp; (8) 他の利用者につきまとい、執拗に追いかける行為など、他の利用者の意思に反する一切の行為 <br/><br/>
				&emsp; (9) 不正アクセス、または他人であるように振る舞うなどの誤解を与える行為 <br/><br/>
				&emsp; (10) 反社会的勢力に対する利益供与その他の協力行為 <br/><br/>
				&emsp; (11) ねずみ講、マルチ商法、ネットワークビジネスその他連鎖講に類する取引の宣伝・告知・勧誘及びその取引行為 <br/><br/>
				&emsp; (12) 当社の承認を得ずに行われる、営利を目的とする行為 <br/><br/>
				&emsp; (13) 宗教活動及び政治活動並びにその宣伝・告知・勧誘 <br/><br/>
				&emsp; (14) 当社の承認を得ずに行われる、同一端末において複数の利用者アカウントを保有する行為 <br/><br/>
				&emsp; (15) 利用者アカウントを、複数人で共有する行為または複数端末で共有する行為 <br/><br/>
				&emsp; (16) 本サービスを運営するためのサーバーまたはネットワークに著しく負荷をかける行為 <br/><br/>
				&emsp; (17) 本サービスを改変、毀損し、または逆アセンブル、逆コンパイル、リバースエンジニアリングする行為 <br/><br/>
				&emsp; (18) 当社が本サービスを通じて提供する各種コンテンツ（本サービス内で利用できるポイント、アイテム等を含みますが、これらに限定されません。以下、「サービス内コンテンツ」といいます）を不正な方法で取得する行為 <br/><br/>
				&emsp; (19) 本サービスのサーバーやネットワークシステムに支障を与える行為、BOT、チートツール、その他の技術的手段を利用してサービスを不正に操作する行為 <br/><br/>
				&emsp; (20) 本サービスの不具合（プログラムの不具合、人為的ミス等による誤表示、誤作動等を含みますが、これらに限られません）を利用する行為 <br/><br/>
				&emsp; (21) 利用者アカウント、サービス内コンテンツその他本サービスにおいて利用者が利用可能な権利または利益の全部または一部を、現金その他の財産上の利益を対価として第三者に譲渡しまたは利用させる行為、ならびにその宣伝・告知・勧誘 <br/><br/>
				&emsp; (22)当社または第三者に対して迷惑、損害を与える行為 <br/><br/>
				&emsp; (23) 類似または同様のお問合せを必要以上に繰り返す等、当社に対し不合理な要求をする行為 <br/><br/>
				&emsp; (24) 当社による本サービスの運営を妨げる行為 <br/><br/>
				&emsp; (25) 上記（1）から（24）までのいずれかの行為に該当する行為を援助または助長する行為 <br/><br/>
				&emsp; (26) その他当社が不適切と判断する行為 <br/><br/>
				<b>第7条. (免責)</b><br/><br/>
				1. 当社は、本サービスが第三者の権利を侵害していないこと、及び本サービスを実行することが第三者の権利を侵害しないことを保証しません。 <br/><br/>
				2. 利用者は、自己の責任と判断において本サービスを利用するものとします。当社は、本サービスの精度、完成度、正確性、妥当性、適用性、有用性及び可用性について保証せず、ならびに利用者の使用目的及び使用する端末への適合性について保証しません。 <br/><br/>
				3. 当社は、利用者が本サービスを通じて行う取引には一切関知しないものとし、本サービスを通じたいかなる情報の正確性も保証しません。利用者は、他の利用者との間で生じた一切の紛争については、自己の責任と判断において解決を図るものとします。 <br/><br/>
				4. 地震、津波、台風、雷、大雨、洪水などの天災、戦争、争議、革命、労働争議などの不可抗力、本サービス運営において必要なシステム等の一時的または大規模なメンテナンス、ネットワーク回線の混雑、プロバイダー等の障害、その他本サービスの運営にかかる障害等により、一時的または長期にわたって本サービスの利用ができなくなる場合、または当社が本サービスそのものの利用を停止しもしくは終了させる場合があります。当社は、利用者に対し、これらの事象に関して責任を負いません。 <br/><br/>
				5. 当社は、利用者に対し、本サービスに対する不正アクセス、コンピューターウイルスの侵入など、第三者の行為を原因として利用者に生じる一切の損害について責任を負いません。 <br/><br/>
				6. 当社は、本サービスに関連して発生した利用者または第三者の損害について責任を負いません。 <br/><br/>
				7. 当社は、本サービス上で実施される広告の広告主等と利用者との間におけるあらゆるトラブル、約束、保証等及びその結果として利用者に生じる一切の損失・損害について責任を負いません。 <br/><br/>
				8. 当社は、本サービスからリンクにより遷移可能な一切の第三者ウェブサイトについて、その適法性、整合性、安全性、正確性、公序良俗に反しないものであることなどを保証しません。 <br/><br/>
				9. 当社は、本サービスに関する利用者からの問い合わせや改善等の意見その他の提案、フィードバック等について、これらの全てに回答をし、または何らかの対応を実施する義務を負わず、自らの裁量及び任意の判断でこれらを実施するものとします。当社は、利用者からの問い合わせ、意見、提案、フィードバック等の内容に対して何らかの対応を実施することがありますが、当社による当該行為は、当該利用者に対し、個別に回答や報告、金銭的な補償などを行う義務を負うものとは解釈されません。<br/><br/>
				<b>第8条. (端末及びアカウントの管理)</b><br/><br/>
				1. 利用者は、本サービスの利用にあたり、その利用に用いる自身の端末及び利用者登録によって取得した自らのアカウント（以下、「利用者アカウント」といいます）を、自らの責任において管理するものとします。 <br/><br/>
				2. 利用者は、自身の端末または利用者アカウント上を利用してなされた行為について、当該端末またはアカウントを現実に利用する者が誰であるかを問わず一切の責任を負うものとし、当該行為により当社または第三者が損害を被った場合、その損害を賠償するものとします。 <br/><br/>
				3. 当社は、利用者の使用する端末の故障、紛失、第三者からのマルウェア、スパイウェア、ウイルス、ハッキング等による攻撃、利用者アカウントの管理不備、使用上の過誤または第三者の使用等により、利用者が損害を被った場合でも、責任を負いません。 <br/><br/>
				4. 利用者は、自身の利用者アカウントが不正に取得され、または端末もしくは利用者アカウントが第三者により利用されるおそれがある場合は、速やかに退会処理をし、または当社に報告をするなど、当社または第三者に損害が発生することを避けるために合理的に必要と考えられるあらゆる措置をとるものとします。<br/><br/>
				<b>第9条　ID及びパスワードの管理</b><br/><br/>
				1. 利用者は、利用者登録等の際に利用者自身で設定したユーザーID及びパスワードを厳重に管理・保管する責任を負うものとします。<br/><br/>
				2. 利用者は、当社の事前の同意がある場合を除き、ユーザーID及びパスワードを第三者に譲渡、売買、承継、貸与、開示又は漏洩等をしてはならないものとします。<br/><br/>
				3. 利用者は、ユーザーID又はパスワードが第三者によって不正に使用されていること又はそのおそれのあることが判明した場合には、直ちに当社に連絡するものとします。<br/><br/>
				4. 利用者は、ユーザーID又はパスワードの管理不十分、使用上の過誤・不手際、第三者の無断使用等に起因する損害につき自ら責任を負うものとし、当社は一切責任を負担しないものとします。<br/><br/>
				<b>第10条　登録内容の変更</b><br/><br/>
				1. 利用者は、当社に届出ている事項の全部又は一部に変更が生じた場合には、当社が別途指定する方法により、すみやかに登録内容を変更するものとします。かかる変更を行わなかった場合には、既に登録済みの事項に基づく当社の業務遂行は適正かつ有効なものであるとみなされるものとします。<br/><br/>
				2. 当社は、利用者が適宜、変更登録を行わなかったことにより何らかの損害が生じたとしても、一切責任を負わないものとします。<br/><br/>
				<b>第11条. (アカウントの停止等)</b><br/><br/>
				当社は、利用者が次の各号のいずれかに該当する場合、本サービスの一部または全部の提供を停止し、利用者アカウントの利用を停止及び削除し、または退会させ、その他当社が適当と考える措置をとることができるものとします。当社は、当該措置についてその理由を開示する義務を負わないものとし、利用者は、当社に対し、当該措置について異議を申し立てることはできないものとします。 なお、利用停止や利用者登録抹消措置がとられた場合でも、当該措置の対象となった利用者は、本サービスの利用により既に発生した支払義務等の本規約上の責任を免れるものではありません。<br/><br/>
				&emsp; (1) 過去に当社の提供する他の規約に違反をしたこと等により、利用者登録の抹消等の処分を受けていたことが判明した場合<br/><br/>
				&emsp; (2) 登録された内容に虚偽の事項が含まれていることが判明した場合（利用者登録時に届出た利用者の所在地が、当社が指定する転送サービス会社の住所であった場合も含みこれに限られないものとします。）<br/><br/>
				&emsp; (3) 過去に当社の提供する他のサービスに関して、正当な理由なく、料金等の支払債務の履行遅延、長期間に亘る受取り不能、返品・交換の拒絶その他の債務不履行があったことが判明した場合<br/><br/>
				&emsp; (4)利用者が、反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者をいいます）であった場合、又は暴力的行為、詐術・脅迫行為、業務妨害行為等違法行為を行った場合<br/><br/>
				&emsp; (5)理由のいかんを問わず、利用者の行為（本サービス外の行為及び不作為を含みます）によって、本サービスの運営に支障が生じ、または妨害がされたと当社が判断した場合、またはそのおそれのあると当社が判断した場合。 <br/><br/>
				&emsp; (6) 本規約その他当社が定める規約（本規約を含みますが、これに限りません）に規定する禁止行為を行う等の違反があった場合またはそのおそれがあると当社が判断した場合<br/><br/>
				&emsp; (7) その他本サービスの利用者として不適当と当社が判断した場合。<br/><br/>
				<b>第12条. (権利義務等の譲渡禁止)</b><br/><br/>
				利用者は、本規約に基づく一切の権利または義務について、第三者への譲渡、承継、質入その他一切の処分をしてはならないものとします。<br/><br/>
				<b>第13条. (退会)</b><br/><br/>
				1. 利用者は、本サービスを退会するときは、当該時点において当社に対して有している一切の債務を、直ちに履行するものとします。 <br/><br/>
				2. 利用者が、本サービスの利用に際して用いる端末について、修理または機種変更等によりこれを交換したときは、当社が特に定める場合を除き、当該新たな端末において、端末を交換する以前の登録情報等の引継ぎ等はできないものとします。<br/><br/>
				<b>第14条. (損害賠償)</b><br/><br/>
				利用者は、本規約に違反したことにより、当社または第三者に損害（合理的な弁護士費用を含みますがこれに限られません）を与えた場合、当社または第三者に対し、その損害を賠償するものとします。<br/><br/>
				<b>第15条. (準拠法及び裁判管轄)</b><br/><br/>
				1. 本規約は、日本法に準拠し、日本法に従って解釈されるものとします。 <br/><br/>
				2. 本サービス、本規約に関する一切の訴訟その他の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。<br/><br/>

				2021年6月4日改訂<br/><br/>
				2021年5月25日改訂<br/><br/>
				2021年5月14日改訂<br/><br/>
				2021年1月15日制定
			</p>
		</Box>
	)
}