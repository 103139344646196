import AuthLayout from "src/layouts/Auth";
import ClientSignIn from "src/views/auth/client/sign-in";
import ForgotPassword from "src/views/auth/forgot-password";
import SignUp from "src/views/auth/sign-up";
import StaffEntitySelect from "src/views/auth/staff/entity-select";
import StaffSignIn from "src/views/auth/staff/sign-in";

const authRoutes = {
	path: 'auth',
	element: <AuthLayout />,
	children: [
		{ path: 'client/sign-in', element: <ClientSignIn /> },
		{ path: 'forgot-password', element: <ForgotPassword /> },
		{ path: 'sign-up', element: <SignUp /> },
		{ path: 'staff/entity-select', element: <StaffEntitySelect /> },
		{ path: 'staff/sign-in', element: <StaffSignIn /> },
	]
}

export default authRoutes;
