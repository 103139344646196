import AssessmentSharpIcon from '@material-ui/icons/AssessmentSharp';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
// import QuestionAnswerSharpIcon from '@material-ui/icons/QuestionAnswerSharp';
import DevicesOtherSharpIcon from '@material-ui/icons/DevicesOtherSharp';
import LayersSharpIcon from '@material-ui/icons/LayersSharp';
import BusinessCenterSharpIcon from '@material-ui/icons/BusinessCenterSharp';
import PeopleSharpIcon from '@material-ui/icons/PeopleSharp';

const staff = [
	{
		href: '/staff/reports',
		icon: AssessmentSharpIcon,
		title: 'navigation.reports',
		tooltip_title: 'tooltip.menu_reports'
	},
	{
		href: '/staff/form',
		icon: DescriptionSharpIcon,
		title: 'navigation.forms',
		tooltip_title: 'tooltip.menu_forms'
	},
	// {
	// 	href: '/staff/questions',
	// 	icon: QuestionAnswerSharpIcon,
	// 	title: 'navigation.questions'
	// },
	{
		href: '/staff/devices',
		icon: DevicesOtherSharpIcon,
		title: 'navigation.devices',
		tooltip_title: 'tooltip.menu_devices'
	},
	{
		href: '/staff/modules',
		icon: LayersSharpIcon,
		title: 'navigation.modules',
		tooltip_title: 'tooltip.menu_modules'
	},
	{
		href: '/staff/staffs',
		icon: BusinessCenterSharpIcon,
		title: 'navigation.staffs',
		tooltip_title: 'tooltip.menu_staffs'
	},
	{
		href: '/staff/users',
		icon: PeopleSharpIcon,
		title: 'navigation.users',
		tooltip_title: 'tooltip.menu_users'
	}
]

export default staff;