import DashboardLayout from "src/layouts/Dashboard";

import Home from "src/views/common/home";
import Reports from "src/views/staff/reports";
import { ReportsParams } from "src/views/staff/reports/params";
import { ReportsDetails } from "src/views/staff/reports/details";
import Questions from "src/views/staff/questions";
import Modules from "src/views/staff/modules";
import Staffs from "src/views/staff/staffs";
import Users from "src/views/staff/users";
import UsersParams from "src/views/staff/users/params";

import Profile from "src/views/common/profile";

const staffRoutes = {
	path: 'staff',
	element: <DashboardLayout />,
	children: [
		{ path: '', element: <Home /> },
		{ path: 'reports', element: <Reports /> },
		{ path: 'reports/:id', element: <ReportsParams /> },
		{ path: 'reports/:id/:uid', element: <ReportsDetails /> },
		{ path: 'form', element: <Home /> },
		{ path: 'questions', element: <Questions /> },
		{ path: 'devices', element: <Home /> },
		{ path: 'modules', element: <Modules /> },
		{ path: 'staffs', element: <Staffs /> },
		{ path: 'users', element: <Users /> },
		{ path: 'users/:id', element: <UsersParams/> },
		{ path: 'profile', element: <Profile />}
	]
}

export default staffRoutes;
