import { useRoutes } from "react-router-dom";
import authRoutes from "./auth";
import clientRoutes from "./client";
import staffRoutes from "./staff";
import commonRoutes from "./common";
import mobileRoutes from "./mobile";

export default function Routes() {
  const routing = useRoutes([
    commonRoutes,
    authRoutes,
    clientRoutes,
    staffRoutes,
    mobileRoutes,
  ]);

  return routing;
}
