import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';

import Alert from '@material-ui/core/Alert';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import LoadingButton from '@material-ui/lab/LoadingButton';

import LanguageMenu from 'src/components/language-menu';
import Loader from 'src/components/loader';
import api from 'src/utilities/axios';

function StaffEntitySelect(props: any): JSX.Element {
	const cookie = new UniversalCookie();
	const navigate = useNavigate();
	const { t } = useTranslation();
	
	const [fetchLoading, setFetchLoading] = React.useState<boolean>(false);
	const [handleLoading, setHandleLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);
	const [entityOptions, setEntityOptions] = React.useState<any[]>([]);
	const [selectedEntity, setSelectedEntity] = React.useState<string>('');
	const [selectedEntityName, setSelectedEntityName] = React.useState<string>('');
	
	function handleEntity(event: React.SyntheticEvent): void {
		event.preventDefault();
		event.persist();

		setHandleLoading(true);
		setMessage(null);

		api({
			pathname: '/staff/' + selectedEntityName + '/login',
			method: 'POST',
			handleResponse: () => {
				setHandleLoading(false);

				cookie.set('workspace', selectedEntity, { path: '/' });
				cookie.set('workspace_name', selectedEntityName, { path: '/' });
				cookie.set('userType', 'staff', { path: '/' });

				navigate('/staff');
			},
			handleError: (error: any) => {
				setHandleLoading(false);
				setMessage(<Alert severity='error'>{error.response.data.detail}</Alert>)
			}
		})
	}

	function fetchData(): void {
		setFetchLoading(true);

		api({
			pathname: '/staff/entities',
			method: 'GET',
			handleResponse: (response: any): void => {
				setFetchLoading(false);
				setMessage(null);
				setEntityOptions(response.data);
			},
			handleError: (error: any): void => {
				setFetchLoading(false);
				setMessage(<Alert severity='error'>{error.response.data.detail}</Alert>)
			}
		})
	}

	React.useEffect(fetchData, []);

	return (
		<React.Fragment>
			<div style={{ textAlign: 'center' }}>
				<img src='/static/img/brands/ResQ.jpg' width='40%' alt='ResQ' />
			</div>
			<br/>
			{message}
			<br/>
			<Loader state={fetchLoading}>
				<form onSubmit={handleEntity}>
					<FormControl
						margin='normal'
						required
						fullWidth
						sx={{ mb: 2 }}
					>
						<InputLabel id='entityLabel'>{t('form.select_entity')}</InputLabel>
						<Select
							labelId='entityLabel'
							label={t('form.select_entity')}
							id='entity'
							value={selectedEntity}
							onChange={(event: any): void => setSelectedEntity(event.target.value)}
						>
							{entityOptions.map((item: any, iteration: number) => (
								<MenuItem
									key={iteration}
									value={item.workspace_name}
									onClick={(): void => setSelectedEntityName(item.name)}
								>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<LoadingButton
						component='button'
						variant='contained'
						type='submit'
						loading={handleLoading}
						fullWidth
						sx={{ mb: 1 }}
					>
						{t('form.go_to_dashboard')}
					</LoadingButton>
				</form>
			</Loader>
			<LanguageMenu />
		</React.Fragment>
	)
}

export default StaffEntitySelect;