import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@material-ui/core/Alert';
import TextField from '@material-ui/core/TextField';

import LoadingButton from '@material-ui/lab/LoadingButton';

import LanguageMenu from 'src/components/language-menu';
import firebase from 'src/utilities/firebase';

export default function ForgotPassword(): JSX.Element {
	const { t } = useTranslation();
	
	const [loading, setLoading] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<JSX.Element>(null);

	function handleResetPassword(event: any): void {
		event.preventDefault();
		event.persist();

		setLoading(true);
		setMessage(null);

		const target = event.target as typeof event.target & {
			email: { value: string }
		}

		firebase
			.auth()
			.sendPasswordResetEmail(target.email.value)
			.then((): void => {
				setLoading(false);
				setMessage(<Alert severity='success'>Password reset link has been sent. Please check your e-mail.</Alert>);
			})
			.catch((error: any): void => {
				setLoading(false);
				setMessage(<Alert severity='error'>{error.message}</Alert>);
			})
	}
	
	return (
		<React.Fragment>
			<div style={{ textAlign: 'center' }}>
				<img src='/static/img/brands/ResQ.jpg' width='40%' alt='ResQ' />
			</div>
			<br/>
			{message}
			<br/>
			<form onSubmit={handleResetPassword}>
				<TextField
					label={t('form.email')}
					variant='outlined'
					name='email'
					type='email'
					autoComplete='email'
					autoFocus
					required
					fullWidth
					sx={{ mb: 2 }}
				/>
				<LoadingButton
					component='button'
					type='submit'
					fullWidth
					variant='contained'
					color='primary'
					loading={loading}
					sx={{ mb: 1 }}
				>
					{t('form.forgot_password')}
				</LoadingButton>
			</form>
			<LanguageMenu />
		</React.Fragment>
	)
}