import i18next from 'i18next';

export function formatDate(date) {
	return date.getFullYear() + '/' + formatWithZero(date.getMonth() + 1) + '/' + formatWithZero(date.getDate());
}

export function formatWeek(date) {
	let month = formatWithZero(date.getMonth() + 1);
	let day = formatWithZero(date.getDate());
	switch(date.getDay()) {
		case 0: return month + '/' + day + ' (' + i18next.t('date.week.sunday') + ')';
		case 1: return month + '/' + day + ' (' + i18next.t('date.week.monday') + ')';
		case 2: return month + '/' + day + ' (' + i18next.t('date.week.tuesday') + ')';
		case 3: return month + '/' + day + ' (' + i18next.t('date.week.wednesday') + ')';
		case 4: return month + '/' + day + ' (' + i18next.t('date.week.thursday') + ')';
		case 5: return month + '/' + day + ' (' + i18next.t('date.week.friday') + ')';
		case 6: return month + '/' + day + ' (' + i18next.t('date.week.saturday') + ')';
		default: return 0;
	}
}

export function formatMonth(date) {
	switch(date.getMonth()) {
		case 0: return i18next.t('date.month.january');
		case 1: return i18next.t('date.month.february');
		case 2: return i18next.t('date.month.march');
		case 3: return i18next.t('date.month.april');
		case 4: return i18next.t('date.month.may');
		case 5: return i18next.t('date.month.june');
		case 6: return i18next.t('date.month.july');
		case 7: return i18next.t('date.month.august');
		case 8: return i18next.t('date.month.september');
		case 9: return i18next.t('date.month.october');
		case 10: return i18next.t('date.month.november');
		case 11: return i18next.t('date.month.december');
		default: return 0;
	}
}

export function formatWithZero(date) {
	if (date < 10)
		return '0' + date;
	else
		return date;
}

export function formatTime(interval, time) {
	let date = new Date(time);
	let minutes = formatWithZero(date.getMinutes());
	let hours = formatWithZero(date.getHours());
	let days = formatWithZero(date.getDate());
	let months = formatWithZero(date.getMonth() + 1);
	let years = date.getFullYear(); 

	switch(interval) {
		case 'hourly': return hours + ':' + minutes;
		case 'daily': return months + '/' + days + ' ' + hours + ':' + minutes;
		case 'weekly': return formatWeek(date);
		case 'monthly': return months + '/' + days;
		case 'yearly': return years + '/' + months;
		default: return ''
	}	
}

export function calculateStartTime(interval, date, index) {
	let copyDate = new Date(date.getTime());

	switch(interval) {
		case 'hourly': copyDate = calculateHourAgo(copyDate, index); break;
		case 'daily': copyDate = calculateDayAgo(copyDate, index); break;
		case 'weekly': copyDate = calculateWeekAgo(copyDate, index); break;
		case 'monthly': copyDate = calculateMonthAgo(copyDate, index); break;
		case 'yearly': copyDate = calculateYearFirst(copyDate, index-1); break;
		default: copyDate = Math.round(Date.now());
	}
	return removeMilliseconds(copyDate);
}

export function calculateEndTime(interval, date, index) {
	let copyDate = new Date(date.getTime());

	switch(interval) {
		case 'hourly': copyDate = calculateHourAgo(copyDate, index-1); break;
		case 'daily': copyDate = calculateDayAgo(copyDate, index-1); break;
		case 'weekly': copyDate = calculateWeekAgo(copyDate, index-1); break;
		case 'monthly': copyDate = calculateMonthAgo(copyDate, index-1); break;
		case 'yearly': copyDate = calculateYearLast(copyDate, index-1); break;
		default: copyDate = Math.round(Date.now());
	}

	return removeMilliseconds(copyDate);

}

export function calculateHourAgo(date, index) {
	date.setHours(date.getHours() - index * 1);
	return date;
}

export function calculateDayAgo(date, index) {
	date.setDate(date.getDate() - index * 1);
	return date;
}

export function calculateWeekAgo(date, index) {
	date.setDate(date.getDate() - index * 7 + 1);
	date.setHours(0,0,0,0);
	return date;
}

export function calculateMonthAgo(date, index) {
	date.setMonth(date.getMonth() - index * 1);
	date.setDate(date.getDate() + 1);
	date.setHours(0,0,0,0);
	return date;
}

export function calculateYearAgo(date, index) {
	date.setFullYear(date.getFullYear() - index * 1);
	date.setHours(0,0,0,0);
	return date;
}

export function calculateYearFirst(date, index) {
	date.setFullYear(date.getFullYear() - index * 1, 0, 1);
	date.setHours(0, 0, 0, 0);
	return date;
}

export function calculateYearLast(date, index) {
	date.setFullYear(date.getFullYear() - index * 1, 12, 0);
	date.setHours(0, 0, 0, 0);
	return date;
}

export function getTimestamp() {
	let now = new Date();
	let minutes = formatWithZero(now.getMinutes());
	let hours = formatWithZero(now.getHours());
	let days = formatWithZero(now.getDate());
	let months = formatWithZero(now.getMonth() + 1);
	let years = now.getFullYear();
	let format = '';

	// format = years + '' + months + '' + days + '' + hours + '' + minutes;
	format = years + '' + months + '' + days;
	return format;
}

export function removeMilliseconds(date) {
	return Math.round(Math.floor(date.getTime()) / 1000);
}