import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Breadcrumbs,
  Card,
  Chip,
  Divider,
  Link,
  Grid,
  Typography
} from "@material-ui/core";
import api from "src/utilities/axios";
import Loader from "src/components/loader";
import { calculateEndTimeUnix } from "src/utilities/date/index.new";
import { BarChart } from "./components/bar-chart";
import { BpChart } from "./components/bp-chart";

import { GraphPagination } from "./components/graph-pagination";
import StepGraph from "./components/step-graph";
import { FilterDialog }  from "./components/filter-dialog";
import { UserReportStateType } from "src/redux/user-report/reducers";
import { removeMilliseconds } from "src/utilities/date";


export interface IPublicProps {
  id: string
}

export interface IReduxStateProps {
  reduxState: {
    userReport: UserReportStateType;
  };
}

export interface IReduxDispatchProps {
  setStartDate: (_: any) => void;
}

export type Props = IPublicProps & IReduxDispatchProps & IReduxStateProps;

export function TimeSeriesComponent(props: Props): JSX.Element {
  const { startDate, interval } = props.reduxState.userReport;

  const [data, setData] = React.useState<any>({});
  const [dbp_data, setDbpData] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dbpLoading, setDbpLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();

  function changeDigit(report: any) {
    if (report.decimal == null) return report;
    if (!report.values) return report;
    const decimal = report.decimal;
    const newvalue = report.values.map((item: any) => {
      return [
        item[0],
        item[1] ? item[1].toFixed(decimal) : item[1],
        item[2] ? item[2].toFixed(decimal) : item[2],
        item[3] ? item[3].toFixed(decimal) : item[3],
      ];
    });
    report.values = newvalue;
    return report;
  }

  function fetchData(): void {
    setLoading(true);

    let pathname = "/user/reports/resq_band/" + props.id;
    pathname +=
      "?start_time=" + removeMilliseconds(startDate);
    pathname +=
      "&end_time=" + calculateEndTimeUnix(interval, startDate);
    pathname += "&interval=" + interval;

    api({
      pathname: pathname,
      method: "GET",
      handleResponse: (response: any): void => {
        setData(changeDigit(response.data.report));
        // if (props.id !== '00005095'){
        setLoading(false);
        // }
        
      },
      handleError: (): void => {
        setLoading(false);
      },
    });

    if (props.id === "00005095"){
      fetchDbpData();
    }
  }

  function fetchDbpData(): void {
    setDbpLoading(true);
    let pathname = "/user/reports/resq_band/00005096";
    pathname +=
      "?start_time=" + removeMilliseconds(startDate);
    pathname +=
      "&end_time=" + calculateEndTimeUnix(interval, startDate);
    pathname += "&interval=" + interval;

    api({
      pathname: pathname,
      method: "GET",
      handleResponse: (response: any): void => {
        setDbpData(changeDigit(response.data.report));
        setDbpLoading(false);
      },
      handleError: (): void => {
        setDbpLoading(false);
      },
    });
  }

  React.useEffect(fetchData, [interval, startDate]);
  
  return (
    <div>
      <Typography variant="h3" sx={{ mb: 1 }}>
        {t("report." + props.id, data.name)}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} color="inherit" to="/client">
          {t("navigation.dashboard")}
        </Link>
        <Link component={RouterLink} color="inherit" to="/client/reports">
          {t("navigation.reports")}
        </Link>
        <Typography color="text.primary">
          {t("report." + props.id, data.name)}
        </Typography>
      </Breadcrumbs>

      <Divider sx={{ my: 3 }} />

      <Grid container justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <Chip
            label={
              t("report.starting_date") + " : " + moment(startDate).format("L")
            }
          />
        </Grid>
        <Grid item>
          <Chip label={t("date.interval") + " : " + t("date." + interval)} />
        </Grid>
        <Grid item>
          <GraphPagination />
        </Grid>
        <Grid item>
          <FilterDialog />
        </Grid>
      </Grid>
      
      <Loader state={loading || dbpLoading}>
        <Card sx={{ boxShadow: 6 }}>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
            sx={{ px: 2, py: 3 }}
          >
            <Grid item xs={12} md={3}>
              <Typography variant="h6" display="inline-block">
                {t("report.graph")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {props.id === "00005097" ? (
                <StepGraph
                  values={data.values && data.values}
                  interval={interval}
                  min={data.y_min}
                  max={data.y_max}
                  reportId={props.id}
                />
              ) : (props.id === "00005095"? (<BpChart
                values={data.values && data.values}
                values_dbp = {dbp_data.values && dbp_data.values}
                interval={interval}
                min={dbp_data.y_min}
                max={data.y_max}
                reportId={props.id}
              />) : (<BarChart
                values={data.values && data.values}
                interval={interval}
                min={data.y_min}
                max={data.y_max}
                reportId={props.id}/>))
                }
            </Grid>
          </Grid>
        </Card>
      </Loader>
    </div>
  );
}
