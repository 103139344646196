import React from 'react';
import { Link as RouterLink } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next';
import UniversalCookie from 'universal-cookie';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import api from 'src/utilities/axios';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { 
	UserX as UserXIcon,
	UserCheck as UserCheckIcon, 
} from 'react-feather';

import {
	DeleteSharp as DeleteSharpIcon, 
	VisibilitySharp as VisibilitySharpIcon,
} from '@material-ui/icons';

import { stableSort, getComparator } from 'src/utilities/data-table';

export default function DataTable(props: any) {
	const { data, updateRender, deleteDialog } = props;
	const { t } = useTranslation();
	const cookie = new UniversalCookie();

	const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const headCells: any = [
		{ id: 'name', align: 'left', disablePadding: false, label: t('table.name') },
		{ id: 'email', align: 'left', disablePadding: false, label: t('table.email') },
		{ id: 'is_active', align: 'left', disablePadding: false, label: t('table.status') },
		{ id: 'actions', align: 'right', disablePadding: false, label: t('table.actions'), tooltip_help: t('tooltip.staff.users_detail') },
	];

	const activeStyle = {
		background: '#28a745',
		color: 'white',
	};

	function handleRequestSort(event: any, property: string) {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
		setPage(newPage);
	};

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function handleDeleteRequest(data: any): void {
		deleteDialog.setState(true);
		deleteDialog.setData(data);
	}

	function handleEnable(user_id: any): void {
		api({
			pathname: '/staff/' + cookie.get('workspace') + '/users/' + user_id + '/enable',
			method: 'POST',
			handleResponse: (): void => {
				updateRender();
			},
			handleError: (): void => {
			}
		})
	}

	function handleDisable(user_id: any): void {
		api({
			pathname: '/staff/' + cookie.get('workspace') + '/users/' + user_id + '/disable',
			method: 'POST',
			handleResponse: (): void => {
				updateRender();
			},
			handleError: (): void => {
			}
		})
	}

	function renderStatus(is_active: boolean){
		if (is_active) {
			return <TableCell align='left'><Chip style={activeStyle} label={t('table.active')}/></TableCell>
		} 
		else {
			return <TableCell align='left'><Chip  label={t('table.inactive')} color="default"/></TableCell>
		}
	}
	
	function renderEnableOrDisable(row: any){
		if (row.is_active) {
			return (
				<Tooltip title={t('table.inactive_action')}>
					<IconButton	onClick={() => handleDisable(row.id)}>
						<UserXIcon />
					</IconButton>
				</Tooltip>)
		} 
		else {
			return (
				<Tooltip title={t('table.active_action')}>
					<IconButton	onClick={() => handleEnable(row.id)}>
						<UserCheckIcon />
					</IconButton>
				</Tooltip>)
		}
	}

	return (
		<Card sx={{ boxShadow: 6 }}>
			<Grid container justifyContent='space-between'>
				<Grid item sx={{ px: 2, py: 3 }}>
					<Typography variant='h6' display='inline'>
						{t('table.users_table')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									{headCells.map((headCell: any) => (
										<TableCell
											key={headCell.id}
											align={headCell.align}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={(event) => handleRequestSort(event, headCell.id)}
											>
												<Box pr={1.5}>{headCell.label}</Box>
												{headCell.tooltip_help && (
													<Tooltip title={headCell.tooltip_help} arrow placement='top'>
														<Button sx={{color: 'text.secondary'}}>
															<HelpOutlineIcon></HelpOutlineIcon>
														</Button>
													</Tooltip>
												)}
												
												
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{stableSort(data, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => (
										<TableRow hover role='checkbox' tabIndex={-1} key={index + 1}>
											<TableCell align='left'>{row.name}</TableCell>
											<TableCell align='left'>{row.email}</TableCell>
											{renderStatus(row.is_active)}
											<TableCell align='right'>
												<Tooltip title={t('table.detailed')}>
													<IconButton component={RouterLink} to={'/staff/users/' + row.id + '?name=' + row.name + '&email=' + row.email}>
														<VisibilitySharpIcon />
													</IconButton>
												</Tooltip>
												<Tooltip title={t('table.delete')}>
													<IconButton	onClick={() => handleDeleteRequest(row.id)}>
														<DeleteSharpIcon />
													</IconButton>
												</Tooltip>
												{renderEnableOrDisable(row)}
											</TableCell>
										</TableRow>
									)
								)}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										count={data.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Card>
	)
}