import { BACKEND_SERVER_URL } from 'src/config'
import firebase from 'src/utilities/firebase';
import axios from 'axios';

const api = (props) => {
	const { 
		pathname = '', 
		method = 'GET', 
		params = {},
		data = {},
		headers = {},
		responseType = 'json',
		handleResponse = (res) => console.log(res.data), 
		handleError = console.error, 
		handleFirebaseError = error => console.log(error)
	} = props;

	let config = {
		method: method,
		url: BACKEND_SERVER_URL + pathname,
		headers: {
			...headers,
			'content-type': 'application/json',
		},
		params: params,
		data: data,
		responseType: responseType,
	}

	firebase
		.auth()
		.onAuthStateChanged((user) => {
			if (user) {
				user.getIdToken(false)
					.then(idToken => {
						axios({
							...config,
							headers: {
								...config.headers,
								'Authorization': idToken
							}
						})
							.then(handleResponse)
							.catch(handleError)
					})
					.catch(handleFirebaseError)
			} else {
				axios(config)
					.then(handleResponse)
					.catch(handleError)
			}
		});
}

const apiAsync = async (props) => {
	try {
		const { 
			pathname = '', 
			method = 'GET', 
			params = {},
			data = {},
			headers = {},
			responseType = 'json',
		} = props;
	
		let config = {
			method: method,
			url: BACKEND_SERVER_URL + pathname,
			headers: {
				...headers,
				'content-type': 'application/json',
			},
			params: params,
			data: data,
			responseType: responseType,
		}

		const idToken = await firebase.auth().currentUser.getIdToken();
		return await axios({
			...config,
			headers: {
				...config.headers,
				'Authorization': idToken
			}
		})
	} catch(error) {
		throw error;
	}
}

const apiCustom = (props) => {
	const { 
		pathname = '', 
		method = 'GET', 
		params = {},
		data = {},
		headers = {},
		responseType = 'json',
		handleResponse = (res) => console.log(res.data), 
		handleError = console.error,
	} = props;

	let config = {
		method: method,
		url: BACKEND_SERVER_URL + pathname,
		headers: {
			...headers,
			'content-type': 'application/json',
		},
		params: params,
		data: data,
		responseType: responseType,
	}

	axios(config)
		.then(handleResponse)
		.catch(handleError)
}

export {
	api as default,
	apiCustom,
	apiAsync
};
