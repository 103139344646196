import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

export default function Stats(props: any) {
	const { id, name, type } = props
	const { t } = useTranslation();

	return (
		<Box component={RouterLink} to={'/staff/reports/' + id}>
			<Card sx={{ boxShadow: 6, pt: 6, pb: 2 }}>
				<CardContent sx={{ textAlign: 'center', py: 0}}>
					<img
						src={'/static/img/dashboard/' + id + '.svg'}
						alt={name}
						width='50%'
					/>
					<Typography variant='h6'>
						{t('report.'+id, name)}
					</Typography>
				</CardContent>
			</Card>
		</Box>
	);
}