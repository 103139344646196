import AssessmentSharpIcon from '@material-ui/icons/AssessmentSharp';
import StorageSharpIcon from '@material-ui/icons/StorageSharp';
import BusinessSharpIcon from '@material-ui/icons/BusinessSharp';

const client = [
	{
		href: '/client/reports',
		icon: AssessmentSharpIcon,
		title: 'navigation.reports'
	},
	{
		href: '/client/data',
		icon: StorageSharpIcon,
		title: 'navigation.data'
	},
	{
		href: '/client/entities',
		icon: BusinessSharpIcon,
		title: 'navigation.entities'
	}
]

export default client;